export default function(data){
  let records = [];
  const nest = (items, id = null, link = 'parentId', depth = 0) => items
      .filter(item => item[link] === id)
      .map(item => {
        item.depth = depth;
        item.prefix = "|__".repeat(depth);
        return { ...item, children: nest(items, item.id, link, depth + 1) }
      });

  const pushToRecords = (items) => items
    .map(item => {
      records.push(item);
      if(item.children.length > 0){
        pushToRecords(item.children);
      }
    });

  pushToRecords(nest(data));

  return records;
}
