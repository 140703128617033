<template>
  <div>
    <PageMenu :breadcrumbs="breadcrumbs" :page="page">
      <template v-slot:toolbar>
        <div v-if="role && !role.isMaster">
          <b-button variant="danger" size="sm" v-b-modal.modal-delete-role>
            <BIconX/> Delete
          </b-button>
          <b-modal id="modal-delete-role" title="Delete role" hide-footer>
            Are you sure that you want to delete this role?<br>
            <b-button class="mt-3" variant="danger" size="sm" @click="deleteRole()">Yes, delete role!</b-button>
          </b-modal>
        </div>
      </template>
    </PageMenu>

    <div class="page-with-menu">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Details" active>
            <b-card-text>
              <FormTemplate v-if="formRole" :form="formRole">
                <b-row>
                  <b-col>
                    <b-form-group class="label-for-xl" label="Name" label-for="inputName">
                        <b-form-input
                            type="text" id="inputName" class="form-control form-control-xl"
                            placeholder="enter the name"
                            v-model="formRole.data.name"
                            :state="formRole.states.name"
                        />
                        <b-form-invalid-feedback v-if="formRole.errors">{{ formRole.errors.name }}</b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group class="label-for-xl" label="Placeholder" label-for="inputPlaceholder">
                        <b-form-input
                            type="text" id="inputPlaceholder" class="form-control form-control-xl"
                            placeholder="enter the name"
                            v-model="formRole.data.placeholder"
                            :state="formRole.states.placeholder"
                        />
                        <b-form-invalid-feedback v-if="formRole.errors">{{ formRole.errors.placeholder }}</b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-form-group class="label-for-xl" label="Parent" label-for="inputParent">
                    <b-form-select v-model="formRole.data.parentId" :options="roles"></b-form-select>
                    <b-form-invalid-feedback v-if="formRole.errors">{{ formRole.errors.parentId }}</b-form-invalid-feedback>
                </b-form-group>
                <b-form-checkbox
                  v-model="formRole.data.isDefault"
                  :state="formRole.states.isDefault"
                  name="isDefault"
                  class="mb-3"
                >
                  Set as default
                </b-form-checkbox>
              </FormTemplate>
            </b-card-text>
          </b-tab>
          <b-tab title="Permissions">
            <b-button @click="toggleSelectAllPermissions()" variant="secondary" size="sm" class="mb-3" v-if="permissions.selected.length !== permissions.options.length">Select all</b-button>
            <b-button @click="toggleSelectAllPermissions()" variant="danger" size="sm" class="mb-3" v-if="permissions.selected.length === permissions.options.length">Deselect all</b-button>
            <FormTemplate v-if="formPermissions" :form="formPermissions">
              <div v-if="permissions.options.length > 0">
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    v-model="permissions.selected"
                    :aria-describedby="ariaDescribedby"
                    name="user-permissions"
                  >
                  <div v-for="p in permissions.parsed" :key="p.group" class="mt-3 mb-3">
                    <div class="text-uppercase">{{ p.group }}</div>
                    <ul class="user-permissions-group">
                      <li v-for="item in p.items" :key="item.id">
                        <b-form-checkbox :value="item.placeholder">
                          {{ item.name }}
                        </b-form-checkbox>
                      </li>
                    </ul>
                  </div>
                  </b-form-checkbox-group>
                </b-form-group>
              </div>
              <b-form-checkbox v-model="updateAllUsers" class="mb-3">
                Update all users having this role
              </b-form-checkbox>
            </FormTemplate>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import PageMenu from '@/common/components/PageMenu';
import {BIconX} from 'bootstrap-vue';
import {Form} from '@/common/utils/parnekt';
import FormTemplate from '@/common/components/Form/Form';
import RolesTree from '@/modules/users/libs/roles.tree';

export default {
    components: {
      PageMenu,
      BIconX,
      FormTemplate
    },
    data(){
      return {
        page: {
            title: "Edit Role",
        },
        breadcrumbs: [
            {
                title: "Dashboard",
                url: this.$router.resolve({name: "app.dashboard"}).href
            },
            {
                title: "Users",
                url: this.$router.resolve({name: "users"}).href
            },
            {
                title: "Roles",
                url: this.$router.resolve({name: "users.roles"}).href
            },
            {
                title: "Edit Role",
            }
        ],
        permissions: {
          selected: [],
          options: [],
          parsed: []
        },
        formRole: null,
        formPermissions: null,
        role: null,
        updateAllUsers: false,
        roles: []
      }
    },
    methods: {
      toggleSelectAllPermissions(){
        //select all
        if(this.permissions.selected.length !== this.permissions.options.length){
          this.permissions.selected = this.permissions.options.map(item => item.placeholder);
        }
        //deselect all
        else{
          this.permissions.selected = [];
        }
      },
      deleteRole(){
        this.$api.delete(`role/${this.$route.params.roleId}`).then(() => {
          this.$router.push({name: "users.roles"});
        })
      },
      getRole(){
        return this.$api.get(`role/${this.$route.params.roleId}`).then(({data}) => {
          this.role = data;

          this.page.title = this.role.name;
        });
      },

      getPermissions(){
        return this.$api.get('permissions',{
          params: {
            perPage: 1000,
            orderBy: "placeholder",
            arrange: "asc"
          }
        }).then(({data}) => {
          this.permissions.options = data.records;
          this.parsePermissions(data.records);
        });
      },

      setPermissions(){
        this.permissions.selected = this.role.permissions.map(item => item.ph);
      },

      parsePermissions(records){
        for(let i in records){
          let item = records[i];
          let m = item.placeholder.match(/^(.*):(.*)$/);
          let group, name = null;
          if(m){
            group = m[1];
            name = m[2];
          }
          else{
            group = 'ungroupped';
            name = item.placeholder;
          }

          let pGroup = this.permissions.parsed.find(o => o.group === group);
          if(pGroup){
            pGroup.items.push({
              id: item.id,
              name: name,
              placeholder: item.placeholder
            })
          }
          else{
            this.permissions.parsed.push({
              group: group,
              items: [
                {
                  id: item.id,
                  name: name,
                  placeholder: item.placeholder
                }
              ]
            });
          }
        }
      },

      getRoles(){
        this.$api.get('roles',{
          params: {
            perPage: 2000,
          }
        }).then(({data}) => {
          let roles = RolesTree(data.records);
          this.roles = [
            {value: null, text: "- top -"}
          ].concat(roles.map(item => {
            return {
              value: item.id,
              text: item.prefix+" "+item.name
            }
          }));
        });
      },
    },
    mounted(){
      Promise.all([
        this.getRole(),
        this.getPermissions(),
        this.getRoles()
      ])
      .then(() => {
        this.setPermissions();
        this.formRole = new Form({
          name: this.role.name,
          placeholder: this.role.placeholder,
          isDefault: this.role.isDefault,
          parentId: this.role.parentId
        },{
          onSubmit: (form) => {
            return this.$api.put(`role/${this.role.id}`,{
              data: {
                name: form.get('name'),
                placeholder: form.get('placeholder'),
                isDefault: form.get('isDefault') ? true : false,
                parentId: form.get('parentId'),
              }
            }).then((res) => {
              this.role = res.data;
              this.page.title = this.role.name;
              return res;
            });
          }
        });

        this.formPermissions = new Form({permissions: null},{
          onSubmit: () => {
            return this.$api.put(`role/${this.role.id}/permissions`,{
              data: {
                updateAllUsers: this.updateAllUsers,
                permissions: this.permissions.options.filter(o => this.permissions.selected.includes(o.placeholder)).map(item => item.id)
              }
            });
          }
        });
      });
      this.$emit('set-sidebar',{active: "users"});
    },
};
</script>
